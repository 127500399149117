/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//const icon = require('./src/images/app-icon.png')

function reload() {
    window.location.reload();
}

exports.onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Aplikacja uległa zmianie. ` +
        `Przeładować dane?`
    )
  
    if (answer === true) {
      reload();
    }
}
/*
exports.onServiceWorkerUpdateFound = () => {

    const showNotification = () => {
        Notification.requestPermission(result => {
            if (result === 'granted') {
                navigator.serviceWorker.ready.then(registration => {
                    registration.showNotification('Aktualizacja', {
                        body: 'Aplikacja uległa zmianie.',
                        icon: icon,
                        vibrate: [200, 100, 200, 100, 200, 100, 400],
                        tag: 'request',
                        actions: [ 
                            {
                                action: reload(), 
                                title: 'aktualizuj'
                            }
                        ]
                    })
                })
            }
        })
    }
    showNotification()
}
*/